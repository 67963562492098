<template>
  <div>
    <a-row :gutter="[8,16]">
      <a-col>
        <div class="iotplt-card-box">
          <!-- 操作详情字段 -->
          <batch-operation-record-batch-modify-info
            :batch-operation-record-info="batchOperationRecordInfo"
          />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[8,16]">
      <a-col>
        <div class="iotplt-card-box">
          <!-- 操作详情列表搜索 -->
          <batch-operation-record-common-details-search @submit="submitSearch" />

          <div class="iotplt-table-operation-buttons">
            <a-space>
              <a-button type="primary" @click="exportData">导出</a-button>
            </a-space>
          </div>

          <!-- 操作详情列表 -->
          <batch-operation-record-batch-modify-details-list
            :data="tableData"
            :loading="tableLoading"
          />

          <pagination
            v-show="pagination.total_count > 0"
            :total-count="pagination.total_count"
            :page.sync="query.page"
            :per-page.sync="query.per_page"
            @change="fetchData"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import BatchOperationRecordCommonDetailsSearch from '@/views/batch_operation_records/detail/CommonDetailsSearch'
import { findBatchOperationDetails, exportBatchOperationDetails } from '@/api/batch_operation_detail'
import BatchOperationRecordBatchModifyInfo from '@/views/batch_operation_records/detail/batch_modify/Info'
import BatchOperationRecordBatchModifyDetailsList from '@/views/batch_operation_records/detail/batch_modify/DetailsList'
import { exportExcel } from '@/api/excel'

export default {
  name: 'BatchOperationRecordBatchModifyShow',
  props: {
    batchOperationRecordInfo: {
      type: Object,
      required: true
    }
  },
  components: {
    BatchOperationRecordBatchModifyDetailsList,
    BatchOperationRecordBatchModifyInfo,
    BatchOperationRecordCommonDetailsSearch,
    Pagination
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.tableLoading = true
      const params = Object.assign({ batch_operation_record_id: this.batchOperationRecordInfo.id }, this.query)
      findBatchOperationDetails(params).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data
          this.pagination = res.pagination
        }
        this.tableLoading = false
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    exportData() {
      const params = Object.assign({ batch_operation_record_id: this.batchOperationRecordInfo.id }, this.query)
      exportBatchOperationDetails(params).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

