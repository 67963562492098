<template>
  <a-table
    size="middle"
    :columns="columns"
    :data-source="data"
    :loading="loading"
    :pagination="false"
    row-key="id"
  />
</template>

<script>

export default {
  name: 'BatchOperationRecordBatchModifyDetailsList',
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          title: 'ICCID',
          width: 350,
          dataIndex: 'iccid'
        },
        {
          title: '修改内容',
          width: 350,
          dataIndex: 'extra_data.value'
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status'
        },
        {
          title: '操作失败原因',
          ellipsis: true,
          dataIndex: 'failure_reason'
        },
        {
          title: '重跑次数',
          width: 100,
          dataIndex: 'run_times'
        }
      ]
    }
  }
}
</script>

